import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/Subscription';
import { APIService } from './core/api.service';
import { Directionality } from '@angular/cdk/bidi';

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, OnDestroy {
  private _router: Subscription;
  private isRtl: boolean;
  currentLang: string;
  // private _dirChangeSubscription = Subscription.EMPTY;

  constructor(private router: Router, private translate: TranslateService,
    dir: Directionality,
    private overlayContainer: OverlayContainer, private api: APIService) {
    if (localStorage.getItem('language') === '' || localStorage.getItem('language') === null) {
      localStorage.setItem('language', 'en');
      localStorage.setItem('apiLanguage', 'En');
      translate.setDefaultLang('en');
      translate.use('en');

    } else {
      translate.setDefaultLang(localStorage.getItem('language'));
      translate.use(localStorage.getItem('language'));

    }

    this.currentLang = localStorage.getItem('language');
    this.isRtl = true;

    // this._dirChangeSubscription = dir.change.subscribe(() => {
    // });
  }
  ngOnDestroy() {
    // this._dirChangeSubscription.unsubscribe();
  }
  ngOnInit() { this.onSetTheme();
    this.loadMapKeyScript(JSON.parse(localStorage.getItem('user_info'))['mapsKey'], 'en');
   
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      const body = document.getElementsByTagName('body')[0];
      const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
      if (body.classList.contains('modal-open')) {
        body.classList.remove('modal-open');
        modalBackdrop.remove();

      }
    });
     
  }
  loadMapKeyScript(mapKey, lang) {
     
    if (mapKey === null || mapKey == undefined || mapKey == '') {
      return;
    } else {
      const node = document.createElement('script');
      node.src = lang === 'en' ?
        'https://maps.googleapis.com/maps/api/js?key=' + mapKey + '&language=en&libraries=places&sensor=false' :
        'https://maps.googleapis.com/maps/api/js?key=' + mapKey + '&language=ar&libraries=places&sensor=false';
      node.type = 'text/javascript';
      node.async = true;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
      localStorage.setItem('mapKey', mapKey);

    }
  }
  @HostBinding('class') componentCssClass = 'default-theme';

  public onSetTheme() {
     const isEn = localStorage.getItem('language') === 'en';
    const value = isEn ? 'ltr' : 'rtl';
    this.overlayContainer.getContainerElement().classList.add(value);
    document.body.dir =isEn ? 'ltr' : 'rtl';
    this.componentCssClass = value;
    this.translate.use(localStorage.getItem('language'));
 

  }
}
