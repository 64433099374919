<div class="logo">
    <a class="simple-text logo-mini" style="width: 60px;margin-right: 0;padding-right: 0;margin-left: 10px;">
        <div class="logo-img">
            <!-- <img style="top:0px" src="../../assets/login_png/1-12.png" /> -->
            <img style="width: 50px;" src="../../assets/images/side/white_mini.png" />
        </div>
    </a>
    <a class="simple-text logo-normal" style="cursor: pointer;"[routerLink]="['dashboard']">
        <div class="logo-img">
            <!-- <img style="top:0px" src="../../assets/login_png/1-12.png" /> -->
            <img style="   width: 150px; height: 45px;  " src="../../assets/images/side/white_full.png" />
        </div>
    </a>
</div>
<div class="sidebar-wrapper">
    <div class="user">
        <div class="photo">
            <img [hidden]="isAdmin" src="{{storeLogo}}" />
            <img [hidden]="!isAdmin"src="../../assets/img/default-avatar.png" />
        </div>
        <div class="user-info">
            <a data-toggle="collapse" href="#collapseExample" class="collapsed">
                <span>
                    {{loggedUser}}
                    <b class="caret"></b>
                </span>
            </a>
            <div class="collapse" id="collapseExample">
                <ul class="nav">
                    <li class="nav-item" *ngFor="let menuitem of adminMenu['Subs']">
                        <a class="nav-link" style="cursor: pointer;"
                            [routerLink]="[ menuitem.screenKey==='dashboard'?'/':'/pages', menuitem.screenKey]"
                            [hidden]="openSource(menuitem.screenKey)=='modal'">
                            <i>
                                <img *ngIf="isAdmin" class="side-img" style="width: 18px; margin: 0 15px;"
                                    src="https://apis.cateringgate.co/Images/Website_Icons/{{menuitem.icon}}">
                                <img *ngIf="!isAdmin" class="side-img" style="width: 18px; margin: 0 15px;"
                                    src="https://apis.cateringgate.co/Images/Website_Icons/{{menuitem.icon}}">
                            </i>
                            <span style="font-size: 14px;">{{menuitem.name}}
                            </span>
                        </a>
                        <a class="nav-link" [hidden]="openSource(menuitem.screenKey)=='screen'" style="cursor: pointer;"
                            (click)="openModal('changePassword')">
                            <i> <img style="width: 18px; margin: 0 15px;"
                                    src="https://apis.cateringgate.co/Images/Website_Icons/{{menuitem.icon}}"></i>

                            <span style="font-size: 14px;">{{menuitem.name}}
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a (click)="logout()" class="nav-link" style="cursor: pointer;">
                            <i> <img style="width: 18px; margin: 0 15px;" src="../../assets/img/side/logout.png"></i>
                            <span style="font-size: 14px;">{{'label.logout'|translate}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item"
            [hidden]="menuitem.active=='0'||menuitem.screenKey=='adminArea'">
            <!--If is a single link-->
            <a routerLinkActive="active"
                [routerLink]="[ menuitem.screenKey==='dashboard'?'/':'/pages', menuitem.screenKey]" class="nav-link"
                *ngIf="menuitem.Subs.length === 0">
                <i>
                    <img *ngIf="isAdmin" class="side-img" style="width: 18px; margin: 0 15px;"
                        src="https://apis.cateringgate.co/Images/Website_Icons/{{menuitem.icon}}">
                    <img *ngIf="!isAdmin" class="side-img" style="width: 18px; margin: 0 15px;"
                        src="https://apis.cateringgate.co/Images/Website_Icons/{{menuitem.icon}}">
                </i>
                <p style="font-size: 14px;">{{menuitem.name}}
                </p>
            </a>
            <!--If it have a submenu-->

            <a data-toggle="collapse" href="#{{menuitem.screenKey+'L1'}}" *ngIf="menuitem.Subs.length >0" style=" border-bottom: 1px solid #7c7c7c;
              border-radius: 0;
              " (click)="updatePS()" class="nav-link">

                <i>
                    <img *ngIf="!isAdmin" class="side-img" style="width: 18px; margin: 0 15px;"
                        src="https://apis.cateringgate.co/Images/Website_Icons/{{menuitem.icon}}">

                    <img *ngIf="isAdmin" style="width: 18px; margin: 0 15px;"
                        src="https://apis.cateringgate.co/Images/Website_Icons/{{menuitem.icon}}">
                </i>
                <p style="font-size: 14px;">{{menuitem.name}}<b style="right: 22px;" class="caret"></b>
                </p>
            </a>

            <!--Display the submenu items-->
            <div  data-toggle="collapse" id="{{menuitem.screenKey+'L1'}}" class="collapse" *ngIf="menuitem.Subs.length >0">
                <ul class="nav">
                    <li routerLinkActive="active" *ngFor="let childitem of menuitem.Subs" class="nav-item"
                        [hidden]="childitem.active=='0'">
                        <a style="margin: 0 1.5em 0 3em;padding:7px 0 0" *ngIf="childitem.screenType === 'screen'"
                            [routerLink]="['/pages/'+menuitem.screenKey, childitem.screenKey]" class="nav-link" (click)="updatePS()" >
                            <i>
                                <img *ngIf="!isAdmin" class="side-img" style="width: 18px; margin: 0 15px;"
                                    src="https://apis.cateringgate.co/Images/Website_Icons/{{childitem.icon}}">

                                <img *ngIf="isAdmin" style="width: 18px; margin: 0 15px;"
                                    src="https://apis.cateringgate.co/Images/Website_Icons/{{childitem.icon}}">
                            </i>
                            <p style="font-size: 12px;">{{childitem.name}}</p>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
    </ul>

</div>
 