import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class APIService {

  constructor(private http: HttpClient) {

  }
  sendData(fd: any, dataSource: string): Observable<any> {
    return this.http.post<any>(dataSource, fd);
  }
  getData(dataSource: string): Observable<any> {
    return this.http.get<any>(dataSource);
  } 
  doChange(fd: FormData, dataSource: string): Observable<any> {
    return this.http.post<any>(dataSource, fd);
  }
  fetchData(fd: FormData, dataSource: string): Observable<any> {
    return this.http.post<any>(dataSource, fd);
  }
  adminLogin(obj): Observable<any> {
    const body = new HttpParams()
      .set('LoadFrom','0')
      .set('PageSize', '20');
    const URL = 'Login/LoginAdmin.php';
    return this.http.post<any>(URL, body);
  }
}
