import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { environment } from 'src/assets/environments/environment';
// import { NotificationService } from 'app/shared/services/notification.service'; 



@Injectable()
export class iterceptor implements HttpInterceptor {
    baseUrl = environment.baseUrl + '/' + environment.MainFolder;
    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({ headers: request.headers.set('Accept-Language', localStorage.getItem('apiLanguage')) });
        request = request.clone({ url: request.url.startsWith('/') ? `${request.url}` : `${this.baseUrl}/${request.url}` });
        request = request.clone({
            headers: request.headers.set('Authorization',
                'Basic U2VsbExpa2VDcmF6eV8yMDIyOlNlbGxMaWtlQ3JhenlAMjAyMg==')
        });
        catchError((error: HttpErrorResponse) => {
            let data = {};
            data = {
                reason: error && error.error.reason ? error.error.reason : '',
                status: error.status
            };
            return throwError(error);
        })
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                // if (event instanceof HttpResponse && event.status !== 200) {
                //     this.notification.getNotification('Process Failed', 'danger')
                // }


                return event;
            }));
    }
}

