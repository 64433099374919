import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
// import { DashboardApiService } from 'app/shared/services/dashboard-api.service';
import { TranslateService } from '@ngx-translate/core';
// import { ApiStoresService } from 'app/shared/services/api-stores.service';
import * as _ from 'lodash';
import { APIService } from '../core/api.service';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../core/notification.service';
import { environment } from 'src/assets/environments/environment';


declare const $: any;

// Metadata
export interface RouteInfo {
    router: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    router: string;
    title: string;
    ab: string;
    type?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [{
    router: '/dashboard',
    title: 'dashboard',
    type: 'link',
    icontype: 'dashboard'
},
{
    router: '/inbox',
    title: 'inbox',
    type: 'sub',
    icontype: 'all_inbox',
    collapse: 'inbox',
    children: [
        { router: 'requests', title: 'requests', ab: 'RQ' },
        { router: 'contactus', title: 'feedback', ab: 'FD' }
    ]
},
{
    router: '/pages',
    title: 'manage_apps',
    type: 'sub',
    icontype: 'settings',
    collapse: 'pages',
    children: [
        { router: 'branches', title: 'branch', ab: 'BR' },
        { router: 'terms_conditions', title: 'terms', ab: 'TC' },
        { router: 'advertisements', title: 'ads', ab: 'AD' },
        { router: 'clients', title: 'clients', ab: 'CL' },
        { router: 'items', title: 'items', ab: 'It' },
        { router: 'categories', title: 'categories', ab: 'CT' },
        { router: 'stores', title: 'stores', ab: 'CT' },
        { router: 'drivers', title: 'drivers', ab: 'CT' }
    ]
}
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    containerFolder = localStorage.getItem('ContainerFolder');
    enLang = localStorage.getItem('language') === 'en' ? true : true;
    public menuItems: any[];
    public adminMenu: any[];
    public menuItemss: any[];
    password = { value: null, validation: true };
    menuTemp;
    public isAdmin: boolean = localStorage.getItem('isAdmin') === 'true' ? true : false;
    storeLogo= localStorage.getItem('store_info') === null ? "../../assets/img/default-avatar.png" :environment.baseUrl+"/Images/Store_Images/"+JSON.parse(localStorage.getItem('store_info')).image
    storeDetails = localStorage.getItem('ownerInfo') === null ? { Name: '' } : JSON.parse(localStorage.getItem('ownerInfo'));
    loggedUser = JSON.parse(localStorage.getItem('user_info'))['name'];
    isMobileMenu() {

        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };
    constructor(
        public dialog: MatDialog,
        private translate: TranslateService
        , private router: Router,
        private apiService: APIService) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;

        translate.use(localStorage.getItem('language'));
    }
    name = this.storeDetails.Name;
    username = this.storeDetails.Username;
    email = this.storeDetails.Email;
    phone = this.storeDetails.Phone;
    getInboxMenu;
    ngOnInit() {

        const adminList = [
            {
                "ID": "26469",
                "ScreenKey": "dashboard",
                "NameAr": "الرئيسة",
                "NameEn": "Dashboard",
                "Description": "",
                "Active": "1",
                "ScreenType": "screen",
                "TapType": "Main",
                "Icon": "Dashboard.svg",
                "Subs": []
            },
            {
                "ID": "26469",
                "ScreenKey": "stores",
                "NameAr": "المتاجر",
                "NameEn": "Stores",
                "Description": "",
                "Active": "1",
                "ScreenType": "screen",
                "TapType": "Main",
                "Icon": "Branchs.png",
                "Subs": []
            },
            {
                "ID": "26469",
                "ScreenKey": "drivers",
                "NameAr": "السائقين",
                "NameEn": "Drivers",
                "Description": "",
                "Active": "1",
                "ScreenType": "screen",
                "TapType": "Main",
                "Icon": "truck-driver.png",
                "Subs": []
            },
            {
                "ID": "26803",
                "ScreenKey": "reports",
                "NameAr": "التقارير",
                "NameEn": "Reports",
                "Description": "",
                "Active": "1",
                "ScreenType": "screen",
                "TapType": "Main",
                "Icon": "reports.png", Subs: [
                    { "Icon": "star.png", "ScreenKey": "top10", "NameAr": "توب 10", "NameEn": "Top 10", "Active": "1", "ScreenType": "screen", },
                    { "Icon": "reports.png", "ScreenKey": 'request_summary', NameAr: 'طلب ملخص', "NameEn": "Request Summary", "Active": "1", "ScreenType": "screen", },
                    { "Icon": "reports.png", "ScreenKey": 'request_reports', NameAr: ' ملخص التقرير', "NameEn": " Request Report", "ScreenType": "screen", "Active": "1" },
                    { "Icon": "reports.png", "ScreenKey": 'deliveryReport', "NameAr": "سجل التوصيل ", "NameEn": "Delivery Report", "ScreenType": "screen", "Active": "1" },
                    { "Icon": "reports.png", "ScreenKey": 'driverHistory', "NameAr": "سجل السائقين ", "NameEn": "Driver Report", "ScreenType": "screen", "Active": "1" }
                ]
            }
        ]

        this.menuItems = JSON.parse(localStorage.getItem('user_info'))['Privileges'];
        this.adminMenu = JSON.parse(localStorage.getItem('user_info'))['Privileges'][0];

    }
    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            const ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    logout() {

        localStorage.removeItem('StoreId');
        if (localStorage.getItem('subLogin') === 'true') {
            localStorage.setItem('user_info', localStorage.getItem('admin_info'));
            localStorage.setItem('isAdmin', 'true');
            localStorage.setItem('subLogin', 'false');
            localStorage.removeItem('admin_info');
            localStorage.removeItem('store_info');
            localStorage.removeItem('mapKey');
            if (this.router.routerState.snapshot.url === '/dashboard') {
                this.router.navigate(['dashboard']);
            } else {
                this.router.navigate(['dashboard']);
            }
        } else {
            localStorage.removeItem('mapKey');
            localStorage.removeItem('admin_info');
            localStorage.removeItem('user_info');
            localStorage.removeItem('store_info');
            localStorage.setItem('loggedUser', 'false');
            localStorage.setItem('subLogin', 'false');
            localStorage.setItem('isAdmin', 'false');
            this.router.navigate(['']);
        }
    }


    save() {
        if (this.password.value == null) {
            this.password.validation = false;
            return;
        } else {
            this.password.validation = true;
            const fd = new FormData();
            let dataScource = 'SystemAdmin/SuperAdminPasswordChange.php';

            fd.append('StoreId', localStorage.getItem('StoreId'));
            if (this.storeDetails.AdminType === 'SuperAdmin') {
                fd.append('SuperAdminId', _.isEmpty(this.storeDetails.ID) ? this.storeDetails.AdminId : this.storeDetails.ID);
                fd.append('NewPassword', this.password.value);
            } else {
                dataScource = "SystemAdmin/EditSystemAdmin.php";
                fd.append('AdminId', _.isEmpty(this.storeDetails.ID) ? this.storeDetails.AdminId : this.storeDetails.ID);
                fd.append('Password', this.password.value);
                fd.append('UpdatedBy', this.storeDetails.Name);
            }

            this.apiService.doChange(fd, dataScource).subscribe(data => {
                if (data.success) {
                    // this.modalRef.hide();
                    // this.notification.getNotification(data.responseMessage, 'success');
                } else {
                    // this.notification.getNotification(data.responseMessage, 'warning')
                }
            });
        }

    }
    openSource(key) {
        switch (key) {
            case 'changePassword':
                return 'modal'
            case 'storeAdmins':
                return 'screen'


            default:
                return 'screen'

        }
    }
    setPath(path) {
        return path;
        // return path.split('.')[0]+'.svg';
    }
    animal: string;
    openModal(type) {
        const dialogRef = this.dialog.open(ChangePassword, {
            width: '650px',
            data: { name: this.name, animal: this.animal }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            this.animal = result;
        });
    }

}
export interface DialogData {
    animal: string;
    name: string;
}
@Component({
    selector: 'change-password',
    templateUrl: 'change_password.html',
})
export class ChangePassword {
    currentPassword;
    newPassword;
    confirm;
    isMatch = true;
    constructor(private api: APIService, private notification: NotificationService,
        public dialogRef: MatDialogRef<ChangePassword>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    update(): void {
        this.isMatch = true
        if (this.newPassword !== this.confirm || this.newPassword == null) {
            this.isMatch = false
            return;
        }
        let dataScource;
        const fd = new FormData;
        if (localStorage.getItem('isAdmin') == 'true') {
            dataScource = 'SystemAdmin/SuperAdminPasswordChange.php'
        } else {
            dataScource = 'StoreAdmin/SuperAdminPasswordChange.php'
        }
        fd.append('NewPassword', this.newPassword)
        fd.append('Username', JSON.parse(localStorage.getItem('user_info'))['username']);
        fd.append('UpdatedBy', JSON.parse(localStorage.getItem('user_info'))['name']);
        this.api.doChange(fd, dataScource).subscribe(data => {
            if (data.success) {
                this.dialogRef.close();
                this.notification.getNotification(data.responseMessage, 'success');
            } else {
                this.notification.getNotification(data.responseMessage, 'warning')
            }
        })


    }
    close(): void {
        this.dialogRef.close();
    }
}