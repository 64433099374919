<h2 mat-dialog-title>Reset Password</h2>
<div mat-dialog-content>

    <!-- <mat-form-field>
        <mat-label>Current Password</mat-label>
        <input matInput [(ngModel)]="currentPassword">
        <mat-error *ngIf="currentPassword== newPassword">
            {{'label.manager_name'|translate}} <strong>{{'inline_msg.required'|translate}}</strong>
        </mat-error>
    </mat-form-field> -->

    <mat-form-field>
        <mat-label>New Password</mat-label>
        <input matInput [(ngModel)]="newPassword" type="password">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Confirm Password</mat-label>
        <input matInput [(ngModel)]="confirm" type="password">
    </mat-form-field>
    <span style="color: red; font-size: 12px;" [hidden]="isMatch">Password not Match</span>
</div>
<div mat-dialog-actions>
    <button class="btn btn-next btn-fill btn-danger btn-wd"  mat-button (click)="close()">Cancel</button>
    <button class="btn btn-next btn-fill btn-info btn-wd"  mat-button (click)="update()">Update</button>
</div>